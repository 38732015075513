import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import WishListBuyers from "../components/WishListBuyers";
import DashboardNavigation from "../components/DashboardNavigation";
import SearchBar from "../components/SearchBar";
import Footer from "../components/Footer";
import axios from "axios";
import styles from "./BuyersDashboardWishList.module.css";
import { useWishlistContext } from "../context/WishlistContext";
const BuyersDashboardWishList = () => {
  const navigate = useNavigate();
  const {
    wishlistItems1,
    wishlistItems2,
    setWishlistItems1,
    wishlistId,
    setWishlistItems2,
  } = useWishlistContext();

  const onGroupButton2Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onGroupButton3Click = useCallback(() => {
    navigate("/marketplace");
  }, [navigate]);

  const onGroupButton4Click = useCallback(() => {
    navigate("/logistics");
  }, [navigate]);

  const onGroupButton5Click = useCallback(() => {
    navigate("/storage");
  }, [navigate]);
  const onGroupButton6Click = useCallback(() => {
    navigate("/farmers-deals");
  }, [navigate]);

  const onGroupButton7Click = useCallback(() => {
    navigate("/track-order");
  }, [navigate]);

  const handleDelete1 = (item) => {
    axios
      .delete(`https://farmjoint-396477185764.us-central1.run.app/wishlists/${wishlistId}/items/${item}`)
      .then((response) => {
        console.log(response.data);
        return axios.get(`https://farmjoint-396477185764.us-central1.run.app/wishlists/${wishlistId}`);
      })
      .then((response) => {
        setWishlistItems1(response.data.items);

        console.log(response.data);
        console.log("wishlistItems1", wishlistItems1);
      })

      .catch((error) => {
        console.error("Error deleting wishlist1 items:", error);
      });
  };

  const handleDelete2 = (item) => {
    axios
      .delete(`https://farmjoint-396477185764.us-central1.run.app/wishlists/${wishlistId}/fjitems/${item}`)
      .then((response) => {
        console.log(response.data);
        return axios.get(`https://farmjoint-396477185764.us-central1.run.app/wishlists/${wishlistId}`);
      })
      .then((response) => {
        setWishlistItems2(response.data.farmjointitems);
        console.log(response.data);
        console.log("wishlistItems2", wishlistItems2);
      })

      .catch((error) => {
        console.error("Error deleting wishlist2 items:", error);
      });
  };

  useEffect(() => {
    axios
      .get(`https://farmjoint-396477185764.us-central1.run.app/wishlists/${wishlistId}/`)
      .then((response) => {
        setWishlistItems1(response.data.items);
        setWishlistItems2(response.data.farmjointitems);
        console.log(response.data);
        console.log("wishlistItems1", wishlistItems1);
        console.log("wishlistItems2", wishlistItems2);
      })

      .catch((error) => {
        console.error("Error  wishlist items:", error);
      });
  }, []);

  return (
    <div className={styles.buyersDashboardWishList}>
      <div className={styles.rectangleParent}>
        <div className={styles.frameChild} />
        <WishListBuyers />
        <div className={styles.contain1}>
          {wishlistItems1.map((item) => {
            return (
              <div className={styles.cabbageParent}>
                <div className={styles.cabbage}>{item.product.name}</div>
                <div className={styles.zenFarms}>Zen farms</div>
                <div className={styles.n500}>
                  N
                  {item.product.sale_price
                    ? item.product.sale_price
                    : item.product.regular_price}{" "}
                </div>
                <img
                  className={styles.pngimgIcon}
                  alt=""
                  src={item.product.images[0].image}
                />
                <button
                  className={styles.phxCircleLight}
                  onClick={() => handleDelete1(item.id)}
                >
                  <img
                    className={styles.vectorIcon}
                    alt=""
                    src="/vector1.svg"
                  />
                </button>
              </div>
            );
          })}
          {wishlistItems2.map((item) => {
            return (
              <div className={styles.cabbageParent}>
                <div className={styles.cabbage}>{item.item.name}</div>
                <div className={styles.zenFarms}>Zen farms</div>
                <div className={styles.n500}>N{item.item.price} </div>
                <img
                  className={styles.pngimgIcon}
                  alt=""
                  src={item.item.images[0].image}
                />
                <button
                  className={styles.phxCircleLight}
                  onClick={() => handleDelete2(item.id)}
                >
                  <img
                    className={styles.vectorIcon}
                    alt=""
                    src="/vector1.svg"
                  />
                </button>
              </div>
            );
          })}
        </div>
        <footer className={styles.footer} id="footer">
          <Footer />
        </footer>
      </div>
      <DashboardNavigation
        onGroupButton2Click={onGroupButton2Click}
        onGroupButton3Click={onGroupButton3Click}
        onGroupButton4Click={onGroupButton4Click}
        onGroupButton5Click={onGroupButton5Click}
        onGroupButton6Click={onGroupButton6Click}
        onGroupButton7Click={onGroupButton7Click}
      />
      <SearchBar
        dimensionText="/phuserlight3.svg"
        dimensionText2="/bibasketfill2.svg"
        propRight="5.4%"
      />
    </div>
  );
};

export default BuyersDashboardWishList;
