import styles from "./Banner1.module.css";
import { useNavigate } from "react-router-dom";

const Banner1 = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/farmers-deals");
  };
  return (
    <div className={styles.rectangleParent}>
      <img
        className={styles.componentChild}
        alt=""
        src="/rectangle-180@2x.png"
      />
      <button className={styles.componentItem} onClick={handleClick}>
        <div className={styles.shopNow}>SHOP NOW</div>
      </button>
      <img
        className={styles.products2RemovebgPreview1Icon}
        alt=""
        src="/products2removebgpreview@2x.png"
      />
      <div
        className={styles.shopProductsFresh}
      >{`Shop products fresh from the farm `}</div>
    </div>
  );
};

export default Banner1;
