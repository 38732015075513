import { useState, useRef, useCallback, useEffect } from "react";
import "antd/dist/antd.min.css";
import { useSearch } from "../context/SearchContext";
import Footer from "../components/Footer";
import axios from "axios";
import { useLocation } from "react-router-dom";
import MarketplaceCategories from "../components/MarketplaceCategories";
import SortBy from "../components/SortBy";
import PortalPopup from "../components/PortalPopup";
import SearchBar from "../components/SearchBar";
import AddToCart from "../components/AddToCart";
import FarmersDealsNavigation from "../components/FarmersDealsNavigation";
import styles from "./FarmersDeals.module.css";
import FarmersDealProductDescripti from "../components/FarmersDealProductDescripti";
import FdProductCard from "../components/FdProductCard";
import Column from "antd/lib/table/Column";

const FarmersDeals = () => {
  const groupButtonRef = useRef(null);

  const [isSortByMarketplaceOpen, setSortByMarketplaceOpen] = useState(false);
  const [isFrameOpen, setFrameOpen] = useState(false);
  const { products, setProducts, setProducts1, choice, setChoice } =
    useSearch();

  const [isAddToCartOpen, setAddToCartOpen] = useState(false);

  const openSortByMarketplace = useCallback(() => {
    setSortByMarketplaceOpen(true);
  }, []);

  const closeSortByMarketplace = useCallback(() => {
    setSortByMarketplaceOpen(false);
  }, []);

  const openFrame = useCallback(() => {
    setFrameOpen(true);
  }, []);

  const closeFrame = useCallback(() => {
    setFrameOpen(false);
  }, []);

  const openAddToCart = useCallback(() => {
    setAddToCartOpen(true);
  }, []);

  const closeAddToCart = useCallback(() => {
    setAddToCartOpen(false);
  }, []);

  useEffect(() => {
    setChoice("Popularity");
    axios
      .get("https://farmjoint-396477185764.us-central1.run.app/product/")
      .then((response) => {
        setProducts(response.data);

        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    if (currentPath === "/farmers-deals" && choice === "Popularity") {
      axios
        .get(`https://farmjoint-396477185764.us-central1.run.app/product/?ordering=popularity_score`)
        .then((response) => {
          setProducts(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error while filtering :", error);
        });
    } else if (currentPath === "/farmers-deals" && choice === "Price") {
      axios
        .get(`https://farmjoint-396477185764.us-central1.run.app/product/?ordering=sale_price`)
        .then((response) => {
          setProducts(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error while filtering:", error);
        });
    } else if (currentPath === "/farmers-deals" && choice === "Rating") {
      axios
        .get(`https://farmjoint-396477185764.us-central1.run.app/product/?ordering=rating`)
        .then((response) => {
          setProducts(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error while filtering:", error);
        });
    } else if (currentPath === "/farmers-deals" && choice === "Newest") {
      axios
        .get(`https://farmjoint-396477185764.us-central1.run.app/product/?ordering=-modified_date`)
        .then((response) => {
          setProducts(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error while filtering:", error);
        });
    } else if (currentPath === "/farmers-deals" && choice === "Oldest") {
      axios
        .get(`https://farmjoint-396477185764.us-central1.run.app/product/?ordering=modified_date`)
        .then((response) => {
          setProducts(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error while filtering:", error);
        });
    } else if (currentPath === "/marketplace" && choice === "Popularity") {
      axios
        .get(`https://farmjoint-396477185764.us-central1.run.app/farmjointitem/?ordering=popularity_score`)
        .then((response) => {
          setProducts1(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error while filtering:", error);
        });
    } else if (currentPath === "/marketplace" && choice === "Price") {
      axios
        .get(`https://farmjoint-396477185764.us-central1.run.app/farmjointitem/?ordering=price`)
        .then((response) => {
          setProducts1(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error while filtering:", error);
        });
    } else if (currentPath === "/marketplace" && choice === "Rating") {
      axios
        .get(`https://farmjoint-396477185764.us-central1.run.app/farmjointitem/?ordering=-price`)
        .then((response) => {
          setProducts1(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error while filtering:", error);
        });
    } else if (currentPath === "/marketplace" && choice === "Newest") {
      axios
        .get(`https://farmjoint-396477185764.us-central1.run.app/farmjointitem/?ordering=-modified_date`)
        .then((response) => {
          setProducts1(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error while filtering:", error);
        });
    } else if (currentPath === "/marketplace" && choice === "Oldest") {
      axios
        .get(`https://farmjoint-396477185764.us-central1.run.app/farmjointitem/?ordering=modified_date`)
        .then((response) => {
          setProducts1(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error while filtering:", error);
        });
    }
  }, [choice, currentPath]);

  return (
    <>
      <div className={styles.farmersDeals}>
        <div className={styles.fixed}>
          <MarketplaceCategories />
          <SearchBar
            dimensionText="/phuserlight3.svg"
            dimensionText2="/bibasketfill2.svg"
            propRight="5.4%"
          />
        </div>
        <div className={styles.rectangleParent}>
          <div className={styles.frameChild} />

          <button
            className={styles.sortByPopularParent}
            ref={groupButtonRef}
            onClick={openSortByMarketplace}
          >
            <div className={styles.sortByPopular}>
              <span className={styles.sortBy}>Sort By</span>
              <span className={styles.popular}> {choice}</span>
            </div>
            <img
              className={styles.riarrowUpSLineIcon}
              alt=""
              src="/riarrowupsline2.svg"
            />
          </button>

          <div className={styles.allProducts}>
            <span className={styles.all}>All</span>
            <span className={styles.products}> Products</span>
          </div>

          <div className={styles.productList}>
            {products.map((product, index) => (
              <FdProductCard
                key={product.id}
                product={product}
                productImage={product.images[0].image}
              />
            ))}
          </div>
          <footer className={styles.footer} id="footer">
            <Footer />
          </footer>
        </div>

        <FarmersDealsNavigation />
      </div>

      {isSortByMarketplaceOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Bottom right"
          relativeLayerRef={groupButtonRef}
          onOutsideClick={closeSortByMarketplace}
        >
          <SortBy onClose={closeSortByMarketplace} />
        </PortalPopup>
      )}
      {isFrameOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeFrame}
        >
          <FarmersDealProductDescripti onClose={closeFrame} />
        </PortalPopup>
      )}

      {isAddToCartOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top right"
          onOutsideClick={closeAddToCart}
        >
          <AddToCart onClose={closeAddToCart} />
        </PortalPopup>
      )}
    </>
  );
};

export default FarmersDeals;
