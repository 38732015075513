import React, { createContext, useContext, useState, useEffect } from "react";
import Cookies from "js-cookie";

const WishlistContext = createContext();

export const useWishlistContext = () => useContext(WishlistContext);

export const WishlistProvider = ({ children }) => {
  const [wishlistId, setWishlistId] = useState(Cookies.get("wishlistId") || "");

  const [wishlistItems1, setWishlistItems1] = useState(() => {
    const wishlistItems1Cookie = Cookies.get("wishlistItems1");
    if (wishlistItems1Cookie) {
      try {
        return JSON.parse(wishlistItems1Cookie);
      } catch (error) {
        console.error("Error parsing 'wishlistItems' cookie:", error);
      }
    }
    return [];
  });
  const [wishlistItems2, setWishlistItems2] = useState(() => {
    const wishlistItems2Cookie = Cookies.get("wishlistItems2");
    if (wishlistItems2Cookie) {
      try {
        return JSON.parse(wishlistItems2Cookie);
      } catch (error) {
        console.error("Error parsing 'wishlistItems' cookie:", error);
      }
    }
    return [];
  });

  useEffect(() => {
    Cookies.set("wishlistId", wishlistId);
    Cookies.set("wishlistItems1", JSON.stringify(wishlistItems1));
    Cookies.set("wishlistItems2", JSON.stringify(wishlistItems2));
  }, [wishlistId, wishlistItems1, wishlistItems2]);

  return (
    <WishlistContext.Provider
      value={{
        wishlistId,
        setWishlistId,
        wishlistItems1,
        setWishlistItems1,
        wishlistItems2,
        setWishlistItems2,
      }}
    >
      {children}
    </WishlistContext.Provider>
  );
};
