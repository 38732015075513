import { useState, useRef, useCallback, useEffect } from "react";
import PortalPopup from "./PortalPopup";
import SortBy from "./SortBy";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import styles from "./BestSelling.module.css";
import BsCard from "./BsCard";
import { useSearch } from "../context/SearchContext";

const BestSelling = ({ isHeart, setHeart }) => {
  const { choice } = useSearch();
  const groupButton11Ref = useRef(null);
  const navigate = useNavigate();
  const [isSortByOpen, setSortByOpen] = useState(false);
  const [stock, setStock] = useState([]);

  useEffect(() => {
    axios
      .get(`https://farmjoint-396477185764.us-central1.run.app/product/?ordering=-popularity_score`)
      .then((response) => {
        console.log(response.data);
        setStock(response.data);
      })
      .catch((error) => {
        console.error("Error updating stocks:", error);
      });
  }, []);

  const openSortBy = useCallback(() => {
    setSortByOpen(true);
  }, []);

  const closeSortBy = useCallback(() => {
    setSortByOpen(false);
  }, []);

  const handleSeeMore = () => {
    navigate("/marketplace");
  };

  return (
    <>
      <div className={styles.bestselling}>
        <div className={styles.bestsellingProducts}>
          <span className={styles.bestselling1}>Bestselling</span>
          <span className={styles.products}> Products</span>
        </div>
        {/* <button
          className={styles.sortByPopularParent}
          ref={groupButton11Ref}
          onClick={openSortBy}
        >
          <div className={styles.sortByPopularContainer}>
            <span className={styles.sortBy}>Sort By</span>
            <span className={styles.popular}> {choice}</span>
          </div>
          <img
            className={styles.riarrowUpSLineIcon}
            alt=""
            src="/riarrowupsline3.svg"
          />
        </button> */}
        {stock.slice(0, 6).map((product, index) => (
          <BsCard product={product} key={index} />
        ))}
      </div>

      {isSortByOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Bottom right"
          relativeLayerRef={groupButton11Ref}
          onOutsideClick={closeSortBy}
        >
          <SortBy onClose={closeSortBy} />
        </PortalPopup>
      )}
    </>
  );
};

export default BestSelling;
