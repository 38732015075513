import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Menu.module.css";

const Menu = ({}) => {
  const navigate = useNavigate();

  const onFarmjointLogo4Click = useCallback(() => {
    navigate("/");
  }, [navigate]);
  const onClose = () => {
    navigate(-1);
  };
  const handleFarmersDeals = () => {
    navigate("/farmers-deals");
  };
  const handleLogistics = () => {
    navigate("/logistics");
  };
  const handleMarketplace = () => {
    navigate("/marketplace");
  };
  const handleTrack = () => {
    navigate("/track-order");
  };
  const handleStorage = () => {
    navigate("/storage");
  };

  return (
    <div className={styles.menu}>
      <div className={styles.menuChild} />
      <div className={styles.menuItem} />
      <div className={styles.myAccountParent}>
        <button className={styles.myAccount}>MY ACCOUNT</button>
        <button className={styles.groupParent}>
          <img className={styles.groupChild} alt="" src="/blackheart.svg" />
          <div className={styles.wishlist}>Wishlist</div>
        </button>
        <button className={styles.trackOrderParent} onClick={handleTrack}>
          <div className={styles.trackOrder}>Track Order</div>
          <img className={styles.groupItem} alt="" src="/carbonmap1.svg" />
        </button>
        <button className={styles.storageParent} onClick={handleStorage}>
          <div className={styles.storage}>Storage</div>
          <img
            className={styles.groupInner}
            alt=""
            src="/mdisilooutline1.svg"
          />
        </button>
        <button className={styles.logisticsParent} onClick={handleLogistics}>
          <div className={styles.logistics}>Logistics</div>
          <img
            className={styles.groupIcon}
            alt=""
            src="/fa6solidtruckfast1.svg"
          />
        </button>
        <button
          className={styles.marketplaceParent}
          onClick={handleMarketplace}
        >
          <div className={styles.marketplace}>Marketplace</div>
          <img
            className={styles.vectorIcon}
            alt=""
            src="/claritystoreline1.svg"
          />
        </button>
        <button
          className={styles.farmersDealsParent}
          onClick={handleFarmersDeals}
        >
          <div className={styles.farmersDeals}>{`Farmers’ Deals `}</div>
          <img
            className={styles.healthiconsfruitsOutline}
            alt=""
            src="/healthiconsfruitsoutline1.svg"
          />
        </button>
      </div>
      <button
        className={styles.farmjointLogo4}
        onClick={onFarmjointLogo4Click}
      />
      <button className={styles.vectorWrapper} onClick={onClose}>
        <img className={styles.vectorIcon} alt="" src="/close.svg" />
      </button>
    </div>
  );
};

export default Menu;
