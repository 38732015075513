import { useCallback, useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import { Empty, Input } from "antd";
import Select from "react-select";
import { Stack } from "@chakra-ui/react";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import { useLogisticsProfile } from "../context/LogisticsProfileContext";
import styles from "./EditProfileLogistics.module.css";
import { useCustomerProfile } from "../context/CustomerProfileContext";

const options = [
  { value: "Abia", label: "Abia" },
  { value: "Adamawa", label: "Adamawa" },
  { value: "Akwa Ibom", label: "Akwa Ibom" },
  { value: "Anambra", label: "Anambra" },
  { value: "Bauchi", label: "Bauchi" },
  { value: "Bayelsa", label: "Bayelsa" },
  { value: "Benue", label: "Benue" },
  { value: "Borno", label: "Borno" },
  { value: "Cross River", label: "Cross River" },
  { value: "Delta", label: "Delta" },
  { value: "Ebonyi", label: "Ebonyi" },
  { value: "Edo", label: "Edo" },
  { value: "Ekiti", label: "Ekiti" },
  { value: "Enugu", label: "Enugu" },
  { value: "FCT", label: "FCT" },
  { value: "Gombe", label: "Gombe" },
  { value: "Imo", label: "Imo" },
  { value: "Jigawa", label: "Jigawa" },
  { value: "Kaduna", label: "Kaduna" },
  { value: "Kano", label: "Kano" },
  { value: "Katsina", label: "Katsina" },
  { value: "Kebbi", label: "Kebbi" },
  { value: "Kogi", label: "Kogi" },
  { value: "Kwara", label: "Kwara" },
  { value: "Lagos", label: "Lagos" },
  { value: "Nasarawa", label: "Nasarawa" },
  { value: "Niger", label: "Niger" },
  { value: "Ogun", label: "Ogun" },
  { value: "Ondo", label: "Ondo" },
  { value: "Osun", label: "Osun" },
  { value: "Oyo", label: "Oyo" },
  { value: "Plateau", label: "Plateau" },
  { value: "Rivers", label: "Rivers" },
  { value: "Sokoto", label: "Sokoto" },
  { value: "Taraba", label: "Taraba" },
  { value: "Yobe", label: "Yobe" },
  { value: "Zamfara", label: "Zamfara" },
];
const zones = [
  { value: "North Central", label: "North Central" },
  { value: "North East", label: "North East" },
  { value: "North West", label: "North West" },
  { value: "South East", label: "South East" },
  { value: "South South", label: "South South" },
  { value: "South West", label: "South West" },
];
const EditProfileLogistics = ({ setEdit }) => {
  const { token } = useAuth();
  const [selectedState, setSelectedState] = useState(null);
  const [selectedZone, setSelectedZone] = useState(null);
  const [disableSelection, setDisableSelection] = useState(false);
  const [disableCheck, setDisableCheck] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [rectangleInputValue, setRectangleInputValue] = useState("");

  const {
    editedLogisticsName,
    setEditedLogisticsName,
    setStreet2,
    setCity2,
    setState2,
    setLogisticsName,
  } = useLogisticsProfile();

  const { editedAddress, setEditedAddress } = useCustomerProfile();

  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption);
    setDisableCheck(true);
    if (!routes.includes(selectedOption.value)) {
      setRoutes([...routes, selectedOption.value]);
    }
  };
  const handleZoneChange = (selectedOption) => {
    setSelectedZone(selectedOption);
    setDisableCheck(true);
    if (!routes.includes(selectedOption.value)) {
      setRoutes([...routes, selectedOption.value]);
    }
  };

  const handleCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked);
    setDisableSelection(e.target.checked);
    // addOrRemoveRoute("Nationwide", e.target.checked);
  };

  const removeRoute = (routeToRemove) => {
    const updatedRoutes = routes.filter((route) => route !== routeToRemove);
    setRoutes(updatedRoutes);
    setIsCheckboxChecked(false);
    setDisableSelection(false);
  };
  useEffect(() => {
    if (routes.length === 0) {
      setDisableCheck(false);
    }
  }, [routes]);
  useEffect(() => {
    if (isCheckboxChecked) {
      setRoutes([...routes, "Nationwide"]);
    } else {
      const updatedRoutes = routes.filter((route) => route !== "Nationwide");
      setRoutes(updatedRoutes);
    }
  }, [isCheckboxChecked]);

  const onGroupButtonClick = () => {
    axios.defaults.headers.common["Authorization"] = `JWT ${token}`;
    axios
      .put("https://farmjoint-396477185764.us-central1.run.app/logistics/my_profile/", editedLogisticsName)
      .then((response) => {
        console.log("Profile updated successfully", response.data);
        setLogisticsName(response.data.logistics_name);
      });

    axios
      .post("https://farmjoint-396477185764.us-central1.run.app/address/", editedAddress)
      .then((response) => {
        console.log("Profile updated successfully", response.data);
        setStreet2(response.data.street);
        setCity2(response.data.city);
        setState2(response.data.state);
      })
      .catch((error) => {
        console.error("Error updating profile:", error);
      });
    setEdit(false);
  };

  return (
    <div className={styles.editProfileLogistics}>
      <div className={styles.editProfileLogisticsChild} />
      <div className={styles.editProfile}>Edit Profile</div>
      <div className={styles.nameOfLogistics}>Name of logistics:</div>
      <Input
        className={styles.editProfileLogisticsItem}
        type="text"
        style={{ width: "20.5%" }}
        value={editedLogisticsName.logistics_name}
        onChange={(e) =>
          setEditedLogisticsName({
            ...editedLogisticsName,
            logistics_name: e.target.value,
          })
        }
      />

      <Input
        className={styles.rectangleInput}
        type="text"
        style={{ width: "20.5%" }}
        size="large"
        value={editedAddress.street}
        onChange={(e) =>
          setEditedAddress({
            ...editedAddress,
            street: e.target.value,
          })
        }
      />
      <Input
        className={styles.rectangleInput1}
        type="text"
        style={{ width: "20.5%" }}
        size="large"
        value={editedAddress.city}
        onChange={(e) =>
          setEditedAddress({
            ...editedAddress,
            city: e.target.value,
          })
        }
      />
      <Input
        className={styles.rectangleInput2}
        type="text"
        style={{ width: "20.5%" }}
        size="large"
        value={editedAddress.state}
        onChange={(e) =>
          setEditedAddress({
            ...editedAddress,
            state: e.target.value,
          })
        }
      />

      <div className={styles.officeAddress}>Office Address</div>
      <div className={styles.officeAddress1}>Street:</div>
      <div className={styles.officeAddress2}>City:</div>
      <div className={styles.officeAddress3}>State:</div>

      <button className={styles.rectangleGroup} onClick={onGroupButtonClick}>
        <div className={styles.groupChild} />
        <div className={styles.save}>Save</div>
      </button>
      <button
        className={styles.rectangleParent8}
        onClick={() => setEdit(false)}
      >
        <div className={styles.groupChild16} />
        <div className={styles.cancel}>Cancel</div>
      </button>

      <div className={styles.component5}>
        <input
          className={styles.component7Child}
          type="checkbox"
          onChange={handleCheckboxChange}
          checked={isCheckboxChecked}
          disabled={disableCheck}
        />
        <Stack className={styles.component5Child}>
          <Select
            id="selectedState"
            options={zones}
            placeholder="- - Select Zone - -"
            onChange={handleZoneChange}
            value={selectedZone}
            isDisabled={disableSelection}
          />
        </Stack>
        <Stack className={styles.component6Child}>
          <Select
            id="selectedZone"
            options={options}
            placeholder="- - Select State - -"
            onChange={handleStateChange}
            value={selectedState}
            isDisabled={disableSelection}
          />
        </Stack>
        <div className={styles.activeRoutes}>Active Routes:</div>
        <div className={styles.activeRoutes1}>Select Routes:</div>
        <div className={styles.abia1}>Nationwide</div>
        <div className={styles.contain}>
          <div className={styles.container}>
            {routes.map((route, index) => (
              <div className={styles.groupButton} key={index}>
                <div className={styles.groupChild12} />
                <button onClick={() => removeRoute(route)}>
                  <img
                    className={styles.groupIcon}
                    alt=""
                    src="/group@2x.png"
                  />
                </button>
                <div className={styles.abia}>{route}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfileLogistics;
