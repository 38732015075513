import { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import SearchBar from "../components/SearchBar";
import StorageNavigation from "../components/StorageNavigation";
import styles from "./StorageDetail.module.css";
import { useStorageContext } from "../context/StorageProductContext";

const StorageDetail = () => {
  const { selectedStorage } = useStorageContext();
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleOptionChange = (value) => {
    if (selectedOptions.includes(value)) {
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    } else {
      setSelectedOptions([...selectedOptions, value]);
    }
  };

  const radius = 67.5;
  const [rating, setRating] = useState(4.2);
  const [progress, setProgress] = useState(rating * 2 * 10);
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - (dashArray * progress) / 100;

  useEffect(() => {
    setProgress(rating * 2 * 10);
  }, [rating]);

  const [rectangleInputValue, setRectangleInputValue] = useState("");
  const [rectangleInput1Value, setRectangleInput1Value] = useState("");
  const [rectangleInput2Value, setRectangleInput2Value] = useState("");
  const [rectangleInput3Value, setRectangleInput3Value] = useState("");
  const [rectangleInput4Value, setRectangleInput4Value] = useState("");
  const [rectangleInput5Value, setRectangleInput5Value] = useState("");
  const [rectangleInput6Value, setRectangleInput6Value] = useState("");
  const [rectangleInput7Value, setRectangleInput7Value] = useState("");
  const [rectangleInput8Value, setRectangleInput8Value] = useState("");
  const [rectangleInput9Value, setRectangleInput9Value] = useState("");

  return (
    <div className={styles.storage1}>
      <div className={styles.frameDiv}>
        <div className={styles.frameChild} />

        <div className={styles.rectangleParent5}>
          <img
            className={styles.rectangleIcon}
            alt=""
            src={selectedStorage.imageSrc}
          />
          <div className={styles.kaniStorageHouse}>{selectedStorage.name}</div>
        </div>
        <div className={styles.frameItem}>
          <div className={styles.groupIcon}>
            <svg width="165px" height="165px" viewBox={`0 0 165px 165px`}>
              <circle
                className={styles.circle}
                cx="82.5px"
                cy="82.5px"
                strokeWidth="15px"
                r={radius}
              />
              <circle
                className={styles.progress}
                cx="82.5px"
                cy="82.5px"
                strokeWidth="15px"
                r={radius}
                style={{
                  strokeDasharray: dashArray,
                  strokeDashoffset: dashOffset,
                }}
                transform={`rotate(-90 82.5 82.5)`}
              />
            </svg>
          </div>
          <b className={styles.rating}>Rating</b>
          <b className={styles.b1}>{rating}</b>
        </div>
        <div className={styles.frameInner}>
          <input
            className={styles.rectangleInput}
            type="text"
            value={rectangleInputValue}
            onChange={(event) => setRectangleInputValue(event.target.value)}
          />
          <input
            className={styles.frameChild1}
            type="text"
            value={rectangleInput1Value}
            onChange={(event) => setRectangleInput1Value(event.target.value)}
          />

          <div className={styles.temperatureControlledStorage}>
            Temperature-Controlled Storage
          </div>
          <div className={styles.street}>Street</div>
          <input
            className={styles.frameChild2}
            type="text"
            value={rectangleInput2Value}
            onChange={(event) => setRectangleInput2Value(event.target.value)}
          />
          <input
            className={styles.frameChild3}
            type="text"
            value={rectangleInput3Value}
            onChange={(event) => setRectangleInput3Value(event.target.value)}
          />
          <input
            className={styles.frameChild4}
            type="text"
            value={rectangleInput4Value}
            onChange={(event) => setRectangleInput4Value(event.target.value)}
          />

          <div className={styles.houseNo}>House No.</div>
          <div className={styles.firstName}>First Name</div>
          <div className={styles.customerInformation}>Customer Information</div>
          <div className={styles.lastName}>Last Name</div>
          <div className={styles.state}>State</div>
          <input
            className={styles.frameChild5}
            type="text"
            value={rectangleInput5Value}
            onChange={(event) => setRectangleInput5Value(event.target.value)}
          />

          <div className={styles.lga}>LGA</div>
          <input
            className={styles.frameChild6}
            type="text"
            value={rectangleInput6Value}
            onChange={(event) => setRectangleInput6Value(event.target.value)}
          />
          <div className={styles.typeOfCrop}>
            Type of Crop Storage (Select multiple)
          </div>
          <input
            className={styles.radio1}
            type="checkbox"
            value="Temperature"
            checked={selectedOptions.includes("Temperature")}
            onChange={() => handleOptionChange("Temperature")}
          />
          <div className={styles.ventilatedStorage}>Ventilated Storage</div>
          <input
            className={styles.radio2}
            type="checkbox"
            value="Ventilated"
            checked={selectedOptions.includes("Ventilated")}
            onChange={() => handleOptionChange("Ventilated")}
          />

          <div className={styles.grainSilo}>Grain Silo</div>
          <input
            className={styles.radio3}
            type="checkbox"
            value="Grain"
            checked={selectedOptions.includes("Grain")}
            onChange={() => handleOptionChange("Grain")}
          />
          <div className={styles.otherSpecify}>Other (Specify)</div>
          <input
            className={styles.radio4}
            type="checkbox"
            value="Other"
            checked={selectedOptions.includes("Other")}
            onChange={() => handleOptionChange("Other")}
          />

          <input
            className={styles.frameChild11}
            type="text"
            value={rectangleInput7Value}
            placeholder="    - - Specify duration - -"
            onChange={(event) => setRectangleInput7Value(event.target.value)}
          />
          <div className={styles.storageDuration}>
            Storage Duration (in Weeks):
          </div>

          <div className={styles.storageDetails}>Storage Details</div>
          <div className={styles.estimatedTonnage}>Estimated Tonnage</div>
          <input
            className={styles.frameChild15}
            type="text"
            value={rectangleInput8Value}
            onChange={(event) => setRectangleInput8Value(event.target.value)}
          />
          <input
            className={styles.frameChild16}
            type="text"
            value={rectangleInput9Value}
            onChange={(event) => setRectangleInput9Value(event.target.value)}
          />
          <div className={styles.storageCapacityNeeded}>
            Storage Capacity Needed
          </div>
          <div className={styles.specificCrops}>Specific Crop(s)</div>

          <b className={styles.fillTheForm}>
            Fill The Form To Request A Storage Facility
          </b>
        </div>

        <div className={styles.frameChild17}>
          <div className={styles.groupIcon1}>
            <svg width="165px" height="165px" viewBox={`0 0 165px 165px`}>
              <circle
                className={styles.circle1}
                cx="82.5px"
                cy="82.5px"
                r={radius}
              />
            </svg>
          </div>
          <b className={styles.noOfCustomers}>No. of Customers</b>
          <b className={styles.b}>32</b>
        </div>

        <div className={styles.frameChild19}>
          <div className={styles.frameChild20}>
            <b className={styles.availableStorage}>Available Storage</b>
            <div className={styles.rectangleParent6}>
              <div className={styles.groupChild8} />
              <div className={styles.groupChild9} />
              <div className={styles.silos}>Silos</div>
            </div>
            <div className={styles.rectangleParent7}>
              <div className={styles.groupChild8} />
              <div className={styles.groupChild9} />
              <div className={styles.barns}>Barns</div>
            </div>
            <div className={styles.rectangleParent8}>
              <div className={styles.groupChild8} />
              <div className={styles.groupChild9} />
              <div className={styles.coldStorage}>Cold Storage</div>
            </div>
          </div>
        </div>

        <button className={styles.groupButton}>
          <div className={styles.groupChild14} />
          <div className={styles.requestFacility}>Request Facility</div>
        </button>
        <div className={styles.ban}>
          <Footer />
        </div>
      </div>

      <StorageNavigation />
      <SearchBar
        dimensionText="/phuserlight6.svg"
        dimensionText2="/bibasketfill2.svg"
        propRight="5.41%"
      />
    </div>
  );
};

export default StorageDetail;
