import { createContext, useContext, useState } from "react";

const GeolocationContext = createContext();

export const useGeolocation = () => {
  return useContext(GeolocationContext);
};

export const GeolocationProvider = ({ children }) => {
  const [locationChanged, setLocationChanged] = useState(false);
  const [farmLocation, setFarmLocation] = useState({
    latitude: null,
    longitude: null,
  });

  return (
    <GeolocationContext.Provider
      value={{
        locationChanged,
        setLocationChanged,
        farmLocation,
        setFarmLocation,
      }}
    >
      {children}
    </GeolocationContext.Provider>
  );
};
