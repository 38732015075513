import styles from "./ResetPassword.module.css";
import CreateNewPassword from "../components/CreateNewPassword";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useState } from "react";

const ResetPassword = () => {
  const { uid, token } = useParams();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordAgain, setNewPasswordAgain] = useState("");
  const new_password = newPassword;
  const re_new_password = newPasswordAgain;

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `https://farmjoint-396477185764.us-central1.run.app/auth/users/reset_password_confirm/`,
        { uid, token, new_password, re_new_password }
      );

      console.log("Password reset successful:", response.data);
      navigate("/");
    } catch (error) {
      console.error("Password reset error:", error);
    }
  };
  return (
    <div className={styles.resetPassword}>
      <CreateNewPassword
        handleSubmit={handleSubmit}
        newPassword={newPassword}
        setNewPassword={setNewPassword}
        newPasswordAgain={newPasswordAgain}
        setNewPasswordAgain={setNewPasswordAgain}
      />
    </div>
  );
};

export default ResetPassword;
