import { useState, useRef, useCallback, useEffect } from "react";
import SortByMarketplace from "../components/SortByMarketplace";
import PortalPopup from "../components/PortalPopup";
import NextPageBar from "../components/NextPageBar";
import AllProducts from "../components/AllProducts";
import axios from "axios";
import MarketplaceCategories from "../components/MarketplaceCategories";
import MarketplaceNavigation from "../components/MarketplaceNavigation";
import SearchBar from "../components/SearchBar";
import Footer from "../components/Footer";
import styles from "./Marketplace.module.css";
import { list } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useSearch } from "../context/SearchContext";

const Marketplace = () => {
  const { setProducts, setProducts1, choice } = useSearch();
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    if (currentPath === "/farmers-deals" && choice === "Popularity") {
      axios
        .get(`https://farmjoint-396477185764.us-central1.run.app/product/?ordering=popularity_score`)
        .then((response) => {
          setProducts(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error while filtering :", error);
        });
    } else if (currentPath === "/farmers-deals" && choice === "Price") {
      axios
        .get(`https://farmjoint-396477185764.us-central1.run.app/product/?ordering=sale_price`)
        .then((response) => {
          setProducts(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error while filtering:", error);
        });
    } else if (currentPath === "/farmers-deals" && choice === "Rating") {
      axios
        .get(`https://farmjoint-396477185764.us-central1.run.app/product/?ordering=rating`)
        .then((response) => {
          setProducts(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error while filtering:", error);
        });
    } else if (currentPath === "/farmers-deals" && choice === "Newest") {
      axios
        .get(`https://farmjoint-396477185764.us-central1.run.app/product/?ordering=-modified_date`)
        .then((response) => {
          setProducts(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error while filtering:", error);
        });
    } else if (currentPath === "/farmers-deals" && choice === "Oldest") {
      axios
        .get(`https://farmjoint-396477185764.us-central1.run.app/product/?ordering=modified_date`)
        .then((response) => {
          setProducts(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error while filtering:", error);
        });
    } else if (currentPath === "/marketplace" && choice === "Popularity") {
      axios
        .get(`https://farmjoint-396477185764.us-central1.run.app/farmjointitem/?ordering=popularity_score`)
        .then((response) => {
          setProducts1(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error while filtering:", error);
        });
    } else if (currentPath === "/marketplace" && choice === "Price") {
      axios
        .get(`https://farmjoint-396477185764.us-central1.run.app/farmjointitem/?ordering=price`)
        .then((response) => {
          setProducts1(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error while filtering:", error);
        });
    } else if (currentPath === "/marketplace" && choice === "Rating") {
      axios
        .get(`https://farmjoint-396477185764.us-central1.run.app/farmjointitem/?ordering=-price`)
        .then((response) => {
          setProducts1(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error while filtering:", error);
        });
    } else if (currentPath === "/marketplace" && choice === "Newest") {
      axios
        .get(`https://farmjoint-396477185764.us-central1.run.app/farmjointitem/?ordering=-modified_date`)
        .then((response) => {
          setProducts1(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error while filtering:", error);
        });
    } else if (currentPath === "/marketplace" && choice === "Oldest") {
      axios
        .get(`https://farmjoint-396477185764.us-central1.run.app/farmjointitem/?ordering=modified_date`)
        .then((response) => {
          setProducts1(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error while filtering:", error);
        });
    }
  }, [choice, currentPath]);
  return (
    <>
      <div className={styles.marketplace}>
        <div className={styles.rectangleParent}>
          <div className={styles.frameChild} />

          <AllProducts />
          <footer className={styles.footer} id="footer">
            <Footer />
          </footer>
        </div>
        <div className={styles.fixed}>
          <SearchBar
            dimensionText="/phuserlight3.svg"
            dimensionText2="/bibasketfill2.svg"
            propRight="5.4%"
          />
          <MarketplaceCategories />
        </div>

        <MarketplaceNavigation />
      </div>
    </>
  );
};

export default Marketplace;
