import { Input } from "@chakra-ui/react";
import styles from "./CreateNewPassword.module.css";

const CreateNewPassword = ({
  newPassword,
  setNewPassword,
  newPasswordAgain,
  setNewPasswordAgain,
  handleSubmit,
}) => {
  return (
    <div className={styles.createNewPassword}>
      <div className={styles.rectangleParent}>
        <div className={styles.groupChild} />
        <div className={styles.createNewPassword1}>Create New Password</div>
        <Input
          className={styles.groupItem}
          placeholder="Enter New Password"
          width="400px"
          w="400px"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />

        <Input
          className={styles.rectangleInput}
          placeholder="Confirm Password"
          width="400px"
          w="400px"
          value={newPasswordAgain}
          onChange={(e) => setNewPasswordAgain(e.target.value)}
        />
        <button className={styles.rectangleGroup} onClick={handleSubmit}>
          <div className={styles.rectangleDiv} />
          <div className={styles.confirm}>Confirm</div>
        </button>
      </div>
    </div>
  );
};

export default CreateNewPassword;
