import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import axios from "axios";
import styles from "./FarmerViewPage.module.css";
import SearchBar from "../components/SearchBar";
import HomeNavigation from "../components/HomeNavigation";

const FarmerViewPage = () => {
  const location = useLocation();
  const farmer = location.state?.farmer;
  const product = location.state?.product;
  const [farm, setFarm] = useState([]);

  const radius = 60.5;
  const [rating, setRating] = useState(4.7);
  const [progress, setProgress] = useState(rating * 2 * 10);
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - (dashArray * progress) / 100;

  useEffect(() => {
    axios
      .get(`https://farmjoint-396477185764.us-central1.run.app/farmers/${farmer?.user_id || product?.farmer_id}`)
      .then((response) => {
        console.log(response.data);
        setFarm(response.data);
      })
      .catch((error) => {
        console.error("Error updating farm page:", error);
      });
  }, [farmer]);

  useEffect(() => {
    setProgress(rating * 2 * 10);
  }, [rating]);

  return (
    <div className={styles.farmer1}>
      <div className={styles.rectangleParent}>
        <div className={styles.frameChild} />
        <div className={styles.rectangleGroup}>
          <img
            className={styles.groupChild}
            alt=""
            src={farm.farm_images?.[0]?.image || ""}
          />
          <div className={styles.healthyLivingFarmland}>{farm.farm_name}</div>
          <p className={styles.farmNameHab}>&nbsp;</p>
          {farm.farm_size && (
            <div className={styles.farmsize}>
              <span className={styles.farmNameHab1}>Farm size:</span>{" "}
              <span>{farm.farm_size}</span> Hectares
            </div>
          )}
        </div>
        <div className={styles.frameItem}>
          <b className={styles.rating}>Rating</b>
          <div className={styles.groupIcon}>
            <svg width="165px" height="165px" viewBox={`0 0 165px 165px`}>
              <circle
                className={styles.circle}
                cx="82.5px"
                cy="82.5px"
                strokeWidth="15px"
                r={radius}
              />
              <circle
                className={styles.progress}
                cx="82.5px"
                cy="82.5px"
                strokeWidth="15px"
                r={radius}
                style={{
                  strokeDasharray: dashArray,
                  strokeDashoffset: dashOffset,
                }}
                transform={`rotate(-90 82.5 82.5)`}
              />
            </svg>
          </div>
          <b className={styles.b1}>{rating}</b>
        </div>

        <div className={styles.rectangleDiv}>
          <div className={styles.frameChild1}>
            <svg width="165px" height="165px" viewBox={`0 0 165px 165px`}>
              <circle
                className={styles.circle1}
                cx="82.5px"
                cy="82.5px"
                r={radius}
              />
            </svg>
          </div>
          <b className={styles.noOfCustomers}>No. of Customers</b>
          <b className={styles.b}>32</b>
        </div>

        <div className={styles.frameChild2}>
          <div className={styles.frameChild3}>
            <b className={styles.verified}>VERIFIED</b>
            <img
              className={styles.mingcutecheck2FillIcon}
              alt=""
              src="/mingcutecheck2fill@2x.png"
            />
          </div>
        </div>
        <div className={styles.frameInner}>
          <div className={styles.rectangleContainer}>
            <button className={styles.groupItem} />
            <img className={styles.vectorIcon} alt="" src="/phheartthin.svg" />
            <img
              className={styles.lettuceRemovebgPreview7Icon}
              alt=""
              src="/png-tomato-18702-3@2x.png"
            />
            <div className={styles.tomato}>Tomato</div>
            <b className={styles.n180kg}>N180/kg</b>
            <b className={styles.n200kg}>N200/kg</b>
            <img className={styles.groupInner} alt="" src="/line-61@2x.png" />
            <button className={styles.groupButton}>
              <div className={styles.groupChild1} />
              <div className={styles.viewProduct}>View Product</div>
            </button>
          </div>
          <div className={styles.groupDiv}>
            <button className={styles.groupItem} />
            <img className={styles.vectorIcon} alt="" src="/phheartthin.svg" />
            <div className={styles.tomato}>Potatoes</div>
            <b className={styles.n180kg}>N180/kg</b>
            <b className={styles.n200kg}>N200/kg</b>
            <img className={styles.groupInner} alt="" src="/line-61@2x.png" />
            <button className={styles.groupButton}>
              <div className={styles.groupChild1} />
              <div className={styles.viewProduct}>View Product</div>
            </button>
            <img
              className={styles.lettuceRemovebgPreview7Icon}
              alt=""
              src="/23potatopngimages-2@2x.png"
            />
          </div>
          <div className={styles.rectangleParent2}>
            <button className={styles.groupItem} />
            <img className={styles.vectorIcon} alt="" src="/phheartthin.svg" />
            <div className={styles.tomato}>Carrots</div>
            <b className={styles.n180kg}>N180/kg</b>
            <b className={styles.n200kg}>N200/kg</b>
            <img className={styles.groupInner} alt="" src="/line-61@2x.png" />
            <button className={styles.groupButton}>
              <div className={styles.groupChild1} />
              <div className={styles.viewProduct}>View Product</div>
            </button>
            <img
              className={styles.lettuceRemovebgPreview7Icon}
              alt=""
              src="/851951bba003827272830485a6b84d4d-largeremovebgpreview-4@2x.png"
            />
          </div>
          <div className={styles.rectangleParent4}>
            <button className={styles.groupItem} />
            <img
              className={styles.lettuceRemovebgPreview7Icon}
              alt=""
              src="/goat.png"
            />
            <img className={styles.vectorIcon} alt="" src="/phheartthin.svg" />
            <div className={styles.tomato}>Goat</div>
            <b className={styles.n180kg}>N180/kg</b>
            <b className={styles.n200kg}>N200/kg</b>
            <img className={styles.groupInner} alt="" src="/line-61@2x.png" />
            <button className={styles.groupButton}>
              <div className={styles.groupChild1} />
              <div className={styles.viewProduct}>View Product</div>
            </button>
          </div>
          <div className={styles.rectangleParent6}>
            <button className={styles.groupItem} />
            <img className={styles.vectorIcon} alt="" src="/phheartthin.svg" />
            <div className={styles.tomato}>Chicken</div>
            <b className={styles.n180kg}>N180/kg</b>
            <b className={styles.n200kg}>N200/kg</b>
            <img className={styles.groupInner} alt="" src="/line-61@2x.png" />
            <button className={styles.groupButton}>
              <div className={styles.groupChild1} />
              <div className={styles.viewProduct}>View Product</div>
            </button>
            <img
              className={styles.lettuceRemovebgPreview7Icon}
              alt=""
              src="/chicken.png"
            />
          </div>
          <div className={styles.rectangleParent8}>
            <button className={styles.groupItem} />
            <img className={styles.vectorIcon} alt="" src="/phheartthin.svg" />
            <div className={styles.tomato}>Red Pepper</div>
            <b className={styles.n180kg}>N180/kg</b>
            <b className={styles.n200kg}>N200/kg</b>
            <img className={styles.groupInner} alt="" src="/line-61@2x.png" />
            <img
              className={styles.lettuceRemovebgPreview7Icon}
              alt=""
              src="/sweetredpeppers--copy600x600removebgpreview-3@2x.png"
            />
            <button className={styles.groupButton}>
              <div className={styles.groupChild1} />
              <div className={styles.viewProduct}>View Product</div>
            </button>
          </div>
        </div>
        <div className={styles.rectangleParent10}>
          <Footer />
        </div>
      </div>
      <SearchBar
        dimensionText="/phuserlight.svg"
        dimensionText2="/bibasketfill.svg"
      />

      <HomeNavigation />
    </div>
  );
};

export default FarmerViewPage;
