import { useRef, useCallback, useState, useEffect } from "react";
import styles from "./FlashSales.module.css";
import axios from "axios";
import SortBy1 from "./SortBy1";
import { useSearch } from "../context/SearchContext";
import PortalPopup from "./PortalPopup";
import FsCard from "./FsCard";

const FlashSales = ({ isHeart, setHeart }) => {
  const frameButtonRef = useRef(null);
  const { choice1 } = useSearch();
  const [isSortByOpen, setSortByOpen] = useState(false);
  const [stock, setStock] = useState([]);

  useEffect(() => {
    axios
      .get(`https://farmjoint-396477185764.us-central1.run.app/flash-sale/`)
      .then((response) => {
        console.log(response.data);
        setStock(response.data);
      })
      .catch((error) => {
        console.error("Error updating stocks:", error);
      });
  }, []);

  const openSortBy = useCallback(() => {
    setSortByOpen(true);
  }, []);

  const closeSortBy = useCallback(() => {
    setSortByOpen(false);
  }, []);
  return (
    <>
      <div className={styles.flashsales}>
        {/* <button
          className={styles.sortByPopularParent}
          ref={frameButtonRef}
          onClick={openSortBy}
        >
          <div className={styles.sortByPopularContainer}>
            <span className={styles.sortBy}>Sort By</span>
            <span className={styles.popular}> {choice1}</span>
          </div>
          <img
            className={styles.riarrowUpSLineIcon}
            alt=""
            src="/riarrowupsline2.svg"
          />
        </button> */}
        <div className={styles.flashSales}>
          <span className={styles.flash}>Flash</span>
          <span className={styles.span}>{` `}</span>
          <span className={styles.sales}>Sales</span>
        </div>
        {stock.map((product, index) => (
          <FsCard product={product} key={index} />
        ))}
      </div>
      {isSortByOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Bottom right"
          relativeLayerRef={frameButtonRef}
          onOutsideClick={closeSortBy}
        >
          <SortBy1 onClose={closeSortBy} />
        </PortalPopup>
      )}
    </>
  );
};

export default FlashSales;
