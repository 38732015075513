import { useState, useRef, useCallback, useEffect } from "react";
import PortalPopup from "./PortalPopup";
import SortBy2 from "./SortBy2";
import styles from "./LimitedStockDeals.module.css";
import LsCard from "./LsCard";
import axios from "axios";
import { useSearch } from "../context/SearchContext";

const LimitedStockDeals = (isHeart, setHeart) => {
  const frameButton1Ref = useRef(null);
  const { choice2 } = useSearch();
  const [isSortBy1Open, setSortBy1Open] = useState(false);
  const [stock, setStock] = useState([]);

  useEffect(() => {
    axios
      .get(`https://farmjoint-396477185764.us-central1.run.app/limited-stock/`)
      .then((response) => {
        console.log(response.data);
        setStock(response.data);
      })
      .catch((error) => {
        console.error("Error updating stocks:", error);
      });
  }, []);

  const openSortBy1 = useCallback(() => {
    setSortBy1Open(true);
  }, []);

  const closeSortBy1 = useCallback(() => {
    setSortBy1Open(false);
  }, []);

  return (
    <>
      <div className={styles.limitedStockDeals}>
        <div className={styles.limitedStockDealsContainer}>
          <span className={styles.limitedStock}>Limited Stock</span>
          <span className={styles.span}>{` `}</span>
          <span className={styles.deals}>Deals</span>
        </div>
        {stock.map((product, index) => (
          <LsCard product={product} key={index} />
        ))}

        {/* <button
          className={styles.sortByPopularParent}
          ref={frameButton1Ref}
          onClick={openSortBy1}
        >
          <div className={styles.sortByPopularContainer}>
            <span className={styles.sortBy}>Sort By</span>
            <span className={styles.popular}> {choice2}</span>
          </div>
          <img
            className={styles.riarrowUpSLineIcon}
            alt=""
            src="/riarrowupsline1.svg"
          />
        </button> */}
      </div>

      {isSortBy1Open && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Bottom right"
          relativeLayerRef={frameButton1Ref}
          onOutsideClick={closeSortBy1}
        >
          <SortBy2 onClose={closeSortBy1} />
        </PortalPopup>
      )}
    </>
  );
};

export default LimitedStockDeals;
