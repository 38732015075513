import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./AvailableLogistics.module.css";
import LogisticsFacilityCard from "./LogisticsFacilityCard";
import { useSearch } from "../context/SearchContext";

const AvailableLogistics = () => {
  const navigate = useNavigate();
  const { facilities, setFacilities } = useSearch();

  const onTransLogisticsCardClick = () => {
    navigate("/logistic-detail");
  };

  useEffect(() => {
    axios
      .get("https://farmjoint-396477185764.us-central1.run.app/all-vehicles/")
      .then((response) => {
        setFacilities(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching logistics data:", error);
      });
  }, []);

  return (
    <div className={styles.availableLogistics}>
      <div className={styles.logisticsAvailable}>
        <span className={styles.logistics}>Available Logistic </span>
        <span className={styles.available}> Companies</span>
      </div>
      <div
        className={styles.productList}
        style={{
          display: "flex",
          position: "relative",
          flexWrap: "wrap",

          gap: "1%",
          width: "100%",
          height: "auto",
        }}
      >
        {facilities.map((facility) => (
          <LogisticsFacilityCard
            key={facility.id}
            facility={facility}
            facilityImage={facility.logistics_images[0].image}
            onTransLogisticsCardClick={onTransLogisticsCardClick}
          />
        ))}
      </div>
    </div>
  );
};

export default AvailableLogistics;
