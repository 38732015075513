import { useState, useEffect, useCallback } from "react";
import PortalPopup from "./PortalPopup";
import styles from "./MpProductCard.module.css";
import ProductPopup1 from "./ProductPopup1";
import axios from "axios";
import Cookies from "js-cookie";
import { useAuth } from "../context/AuthContext";
import { useWishlistContext } from "../context/WishlistContext";

const MpProductCard = ({ product, productImage }) => {
  const [isPopUpOpen, setPopUpOpen] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);
  const { wishlistId, setWishlistItems2, wishlistItems2 } =
    useWishlistContext();
  const { userRole } = useAuth();
  const [isHeart, setHeart] = useState(() => {
    return Cookies.get(`isHeart_${product.id}`) === "true";
  });

  const openPopUp = useCallback(() => {
    setPopUpOpen(true);
  }, []);

  useEffect(() => {
    if (reloadPage) {
      window.location.reload();
    }
  }, [reloadPage]);

  const closePopUp = useCallback(() => {
    setPopUpOpen(false);
  }, []);

  const handleRedHeart = () => {
    const updatedIsHeart = true;
    setHeart(updatedIsHeart);
    Cookies.set(`isHeart_${product.id}`, updatedIsHeart);
    const data = {
      item_id: product.id,
    };

    axios
      .post(
        `https://farmjoint-396477185764.us-central1.run.app/wishlists/${wishlistId}/fjitems/`,
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        return axios.get(`https://farmjoint-396477185764.us-central1.run.app/wishlists/${wishlistId}/`);
      })
      .then((response) => {
        setWishlistItems2(response.data.farmjointitems);
        console.log(response.data);
        console.log("wishlistItems2", wishlistItems2);
        setReloadPage(true);
      })

      .catch((error) => {
        console.error("Error  wishlist items:", error);
      });
  };
  const handleWhiteHeart = () => {
    const updatedIsHeart = false;
    setHeart(updatedIsHeart);
    Cookies.set(`isHeart_${product.id}`, updatedIsHeart);
    const data = {
      item_id: product.id,
    };

    axios
      .post(
        `https://farmjoint-396477185764.us-central1.run.app/wishlists/${wishlistId}/fjitems/`,
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        return axios.get(`https://farmjoint-396477185764.us-central1.run.app/wishlists/${wishlistId}/`);
      })
      .then((response) => {
        setWishlistItems2(response.data.farmjointitems);
        console.log(response.data);
        console.log("wishlistItems2", wishlistItems2);
        setReloadPage(true);
      })

      .catch((error) => {
        console.error("Error deleting wishlist items:", error);
      });
  };

  return (
    <>
      <div className={styles.rectangleParent}>
        <div className={styles.componentChild} />
        <div className={styles.lettuce}>{product.name}</div>
        <b className={styles.n180kg}>₦{product.price}/kg</b>
        <img
          className={styles.lettuceRemovebgPreview2Icon}
          alt=""
          src={productImage}
          onClick={openPopUp}
        />
        <button className={styles.rectangleGroup} onClick={openPopUp}>
          <div className={styles.groupChild} />
          <div className={styles.viewProduct}>View Product</div>
        </button>

        <img className={styles.componentInner} alt="" src="/group-344.svg" />
        {userRole === "Customer" ? (
          isHeart === true ? (
            <button onClick={handleWhiteHeart}>
              <img
                className={styles.vectorIcon}
                alt=""
                src="/phheartthin1.svg"
              />
            </button>
          ) : (
            <button onClick={handleRedHeart}>
              <img
                className={styles.vectorIcon}
                alt=""
                src="/phheartthin.svg"
              />
            </button>
          )
        ) : (
          ""
        )}
      </div>
      {isPopUpOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closePopUp}
        >
          <ProductPopup1
            onClose={closePopUp}
            product={product}
            productImage={productImage}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default MpProductCard;
