import { useState } from "react";
import StorageNavigation from "../components/StorageNavigation";
import "antd/dist/antd.min.css";
import Footer from "../components/Footer";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import axios from "axios";
import AvailableStorage from "../components/AvailableStorage";
import StorageFilter from "../components/StorageFilter";
import NextPageBar from "../components/NextPageBar";
import SearchBar from "../components/SearchBar";
import styles from "./Storage.module.css";
import { useSearch } from "../context/SearchContext";

const Storage = () => {
  const [search, setSearch] = useState([]);
  const { setFacilities1 } = useSearch();
  const [showDropdown, setShowDropdown] = useState(false);
  const [input, setInput] = useState();

  const onSearch = () => {
    setShowDropdown(false);
    axios
      .get(`https://farmjoint-396477185764.us-central1.run.app/all-facilities/?search=${input}`)
      .then((response) => {
        setFacilities1(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error while searching:", error);
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setShowDropdown(false);
      axios
        .get(`https://farmjoint-396477185764.us-central1.run.app/all-facilities/?search=${input}`)
        .then((response) => {
          setFacilities1(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error while searching:", error);
        });
    }
  };
  const getFilters = () => {
    axios
      .get(`https://farmjoint-396477185764.us-central1.run.app/all-facilities?search=${input}`)
      .then((response) => {
        setSearch(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error while searching:", error);
      });
  };
  const select = (item) => {
    axios
      .get(`https://farmjoint-396477185764.us-central1.run.app/all-facilities/?search=${item.type}`)
      .then((response) => {
        setFacilities1(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error while searching:", error);
      });
  };

  return (
    <div
      className={styles.storage}
      onClick={() => {
        setShowDropdown(false);
      }}
    >
      <div className={styles.rectangleParent}>
        <div className={styles.frameChild} />
        <div className={styles.pexelsTomFisk97080112Wrapper}>
          <img
            className={styles.pexelsTomFisk97080112Icon}
            alt=""
            src="/pexelstomfisk9708011-22@2x.png"
          />
          <div className={styles.find}>Find a Storage Facility Around...</div>
        </div>

        <StorageFilter />
        <Input
          className={styles.frameItem}
          type="search"
          style={{
            width: "53.6%",
            borderTopLeftRadius: "10px",
            borderBottomLeftRadius: "10px",
          }}
          prefix={<SearchOutlined />}
          size="large"
          placeholder="    Input type or location"
          onChange={(e) => {
            setInput(e.target.value);
            getFilters();
            setShowDropdown(true);
          }}
          onKeyDown={handleKeyDown}
        />
        {showDropdown && (
          <div className={styles.searchDropdown}>
            {search.slice(0, 10).map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  select(item);
                  setShowDropdown(false);
                }}
              >
                <img
                  className={styles.pic}
                  alt=""
                  src={item.storage_images[0].image}
                />{" "}
                <span>
                  <b className={styles.item}>
                    {item.type || item.storage_name}
                  </b>
                  <b className={styles.in}>in</b>
                  <b className={styles.cat}>{item.state}</b>
                </span>
              </div>
            ))}
          </div>
        )}

        <button className={styles.frameParent} onClick={onSearch}>
          <div className={styles.rectangleWrapper}>
            <div className={styles.frameInner} />
          </div>
          <div className={styles.searchWrapper}>
            <div className={styles.search}>Search</div>
          </div>
        </button>
        <AvailableStorage />
        <footer className={styles.footer} id="footer">
          <Footer />
        </footer>
      </div>
      <StorageNavigation />
      <SearchBar
        dimensionText="/phuserlight6.svg"
        dimensionText2="/bibasketfill2.svg"
        propRight="5.41%"
      />
    </div>
  );
};

export default Storage;
