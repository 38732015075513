import { useCallback, useState, useEffect } from "react";
import SearchBar from "../components/SearchBar";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import LogisticsNavigation from "../components/LogisticsNavigation";
import styles from "./LogisticDetail.module.css";
import { useProductContext } from "../context/LogisticProductContext";

const LogisticDetail = () => {
  const { selectedProduct } = useProductContext();
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleOptionChange = (value) => {
    if (selectedOptions.includes(value)) {
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    } else {
      setSelectedOptions([...selectedOptions, value]);
    }
  };
  const radius = 67.5;
  const [rating, setRating] = useState(4.7);
  const [progress, setProgress] = useState(rating * 2 * 10);
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - (dashArray * progress) / 100;

  useEffect(() => {
    setProgress(rating * 2 * 10);
  }, [rating]);

  const [rectangleInputValue, setRectangleInputValue] = useState("");
  const [rectangleInput1Value, setRectangleInput1Value] = useState("");
  const [rectangleInput2Value, setRectangleInput2Value] = useState("");
  const [rectangleInput3Value, setRectangleInput3Value] = useState("");
  const [rectangleInput4Value, setRectangleInput4Value] = useState("");
  const [rectangleInput5Value, setRectangleInput5Value] = useState("");
  const [rectangleInput6Value, setRectangleInput6Value] = useState("");
  const [rectangleInput7Value, setRectangleInput7Value] = useState("");
  const [rectangleInput8Value, setRectangleInput8Value] = useState("");

  return (
    <div className={styles.logistics24}>
      <div className={styles.frameDiv}>
        <div className={styles.frameChild} />

        <div className={styles.rectangleParent5}>
          <img
            className={styles.rectangleIcon}
            alt=""
            src={selectedProduct.imageSrc}
          />
          <div className={styles.diamondLogistics}>{selectedProduct.name}</div>
        </div>
        <div className={styles.frameItem}>
          <div className={styles.groupIcon}>
            <svg width="165px" height="165px" viewBox={`0 0 165px 165px`}>
              <circle
                className={styles.circle}
                cx="82.5px"
                cy="82.5px"
                strokeWidth="15px"
                r={radius}
              />
              <circle
                className={styles.progress}
                cx="82.5px"
                cy="82.5px"
                strokeWidth="15px"
                r={radius}
                style={{
                  strokeDasharray: dashArray,
                  strokeDashoffset: dashOffset,
                }}
                transform={`rotate(-90 82.5 82.5)`}
              />
            </svg>
          </div>
          <b className={styles.rating}>Rating</b>
          <b className={styles.b1}>{rating}</b>
        </div>
        <div className={styles.frameInner}>
          <input
            className={styles.rectangleInput}
            type="text"
            value={rectangleInputValue}
            onChange={(event) => setRectangleInputValue(event.target.value)}
          />
          <input
            className={styles.frameChild1}
            type="text"
            value={rectangleInput1Value}
            onChange={(event) => setRectangleInput1Value(event.target.value)}
          />

          <div className={styles.truck}>Truck</div>
          <div className={styles.street}>Street</div>
          <input
            className={styles.frameChild2}
            type="text"
            value={rectangleInput2Value}
            onChange={(event) => setRectangleInput2Value(event.target.value)}
          />
          <input
            className={styles.frameChild3}
            type="text"
            value={rectangleInput3Value}
            onChange={(event) => setRectangleInput3Value(event.target.value)}
          />
          <input
            className={styles.frameChild4}
            type="text"
            value={rectangleInput4Value}
            onChange={(event) => setRectangleInput4Value(event.target.value)}
          />

          <div className={styles.houseNo}>House No.</div>
          <div className={styles.firstName}>First Name</div>
          <div className={styles.customerInformation}>Customer Information</div>
          <div className={styles.lastName}>Last Name</div>
          <div className={styles.state}>State</div>
          <input
            className={styles.frameChild5}
            type="text"
            value={rectangleInput5Value}
            onChange={(event) => setRectangleInput5Value(event.target.value)}
          />

          <div className={styles.lga}>LGA</div>
          <input
            className={styles.frameChild6}
            type="text"
            value={rectangleInput6Value}
            onChange={(event) => setRectangleInput6Value(event.target.value)}
          />
          <div className={styles.typeOfVehicle}>
            Type of Vehicle (Select multiple)
          </div>
          <input
            className={styles.radio1}
            type="checkbox"
            value="Truck"
            checked={selectedOptions.includes("Truck")}
            onChange={() => handleOptionChange("Truck")}
          />
          <div className={styles.trailer}>Trailer</div>
          <input
            className={styles.radio2}
            type="checkbox"
            value="Trailer"
            checked={selectedOptions.includes("Trailer")}
            onChange={() => handleOptionChange("Trailer")}
          />
          <div className={styles.van}>Van</div>
          <input
            className={styles.radio3}
            type="checkbox"
            value="Van"
            checked={selectedOptions.includes("Van")}
            onChange={() => handleOptionChange("Van")}
          />
          <div className={styles.otherSpecify}>Other (Specify)</div>
          <input
            className={styles.radio4}
            type="checkbox"
            value="Other"
            checked={selectedOptions.includes("Other")}
            onChange={() => handleOptionChange("Other")}
          />
          <div className={styles.storageDetails}>Logistics Details</div>
          <div className={styles.estimatedTonnage}>Estimated Tonnage</div>
          <input
            className={styles.frameChild11}
            type="text"
            value={rectangleInput7Value}
            onChange={(event) => setRectangleInput7Value(event.target.value)}
          />
          <input
            className={styles.frameChild12}
            type="text"
            value={rectangleInput8Value}
            onChange={(event) => setRectangleInput8Value(event.target.value)}
          />
          <div className={styles.vehicleCapacityNeeded}>
            Vehicle Capacity Needed
          </div>
          <div className={styles.specificCrops}>Specific Crop(s)</div>

          <b
            className={styles.fillTheForm}
          >{`Fill The Form To Request A Logistics `}</b>
        </div>
        <div className={styles.frameChild13}>
          <div className={styles.groupIcon1}>
            <svg width="165px" height="165px" viewBox={`0 0 165px 165px`}>
              <circle
                className={styles.circle1}
                cx="82.5px"
                cy="82.5px"
                r={radius}
              />
            </svg>
          </div>
          <b className={styles.noOfCustomers}>No. of Customers</b>
          <b className={styles.b}>32</b>
        </div>

        <div className={styles.frameChild15}>
          <div className={styles.frameChild17}>
            <b className={styles.availableVehicles}>{`Available Vehicles `}</b>
            <div className={styles.rectangleParent6}>
              <div className={styles.groupChild8} />
              <div className={styles.groupChild9} />
              <div className={styles.trucks}>Trucks</div>
            </div>
            <div className={styles.rectangleParent8}>
              <div className={styles.groupChild8} />
              <div className={styles.groupChild9} />
              <div className={styles.trailers}>Trailers</div>
            </div>
            <div className={styles.rectangleParent10}>
              <div className={styles.groupChild8} />
              <div className={styles.groupChild9} />
              <div className={styles.vans}>Vans</div>
            </div>
          </div>
        </div>

        <div className={styles.frameChild16}>
          <div className={styles.frameChild18}>
            <b className={styles.activeRoutes}>{`Active Routes `}</b>

            <div className={styles.rectangleParent7}>
              <div className={styles.groupChild8} />
              <div className={styles.groupChild11} />
              <div className={styles.abiaState}>Abia State</div>
            </div>

            <div className={styles.rectangleParent9}>
              <div className={styles.groupChild8} />
              <div className={styles.groupChild11} />
              <div className={styles.trailers}>FCT</div>
            </div>

            <div className={styles.rectangleParent11}>
              <div className={styles.groupChild8} />
              <div className={styles.groupChild11} />
              <div className={styles.vans}>Oyo State</div>
            </div>
          </div>
        </div>
        <button className={styles.groupButton}>
          <div className={styles.groupChild20} />
          <div className={styles.requestLogistics}>{`Request  Logistics `}</div>
        </button>
        <div className={styles.ban}>
          <Footer />
        </div>
      </div>
      <LogisticsNavigation />
      <SearchBar
        dimensionText="/phuserlight6.svg"
        dimensionText2="/bibasketfill2.svg"
        propRight="5.41%"
      />
    </div>
  );
};
export default LogisticDetail;
