import { useEffect, useState } from "react";
import styles from "./SelectLogisticsCompany.module.css";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import { useCartContext1 } from "../context/CartContext1";

const SelectLogisticsCompany = ({
  onClose,
  setSelectedLogistics,
  availableLogistics,
  setAvailableLogistics,
  setItem,
  finalCost,
  handleSetSelected,
  total,
}) => {
  const { token } = useAuth();
  const { cartId } = useCartContext1();
  const [groupInputValue, setGroupInputValue] = useState("");
  const [selectedButton, setSelectedButton] = useState(null);
  const [isClicked, setClicked] = useState(false);

  const handleButtonClick = (index) => {
    setSelectedLogistics(true);
    setSelectedButton(index);
    setClicked(true);
    handleSetSelected(availableLogistics[index]);

    const formData = new FormData();
    formData.append("assigned_logistics_id", availableLogistics[index].user);

    axios.defaults.headers.common["Authorization"] = `JWT ${token}`;
    axios
      .post(`https://farmjoint-396477185764.us-central1.run.app/carts/${cartId}/suitable-logistics/`, formData)
      .then((response) => {
        const Data = response.data;
        setItem(Data);
      })
      .catch((error) => {
        console.error("Error while posting logistics:", error);
      });
  };

  const handleConfirm = () => {
    onClose();
  };

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = `JWT ${token}`;
    axios
      .get(`https://farmjoint-396477185764.us-central1.run.app/carts/${cartId}/suitable-logistics/`)
      .then((response) => {
        const Data = response.data;
        console.log(Data);
        setAvailableLogistics(Data);
      })
      .catch((error) => {
        console.error("Error while getting logistics:", error);
      });
  }, []);

  return (
    <div className={styles.selectLogisticsCompany}>
      <div className={styles.selectACompany}>
        Select a company that will deliver your products to you.
      </div>
      <div className={styles.selectALogistics}>Select a Logistics Company</div>
      <input
        className={styles.selectLogisticsCompanyChild}
        placeholder="  Search for a logistics company"
        type="search"
        value={groupInputValue}
        onChange={(event) => setGroupInputValue(event.target.value)}
      />
      <button onClick={onClose}>
        <img
          className={styles.phxCircleLightIcon}
          alt=""
          src="/vector111.svg"
        />
      </button>
      <div className={styles.rectangleParent}>
        <div className={styles.frameChild} />

        {availableLogistics.map((company, index) => (
          <button
            className={styles.rectangleContainer}
            key={index}
            onClick={() => handleButtonClick(index)}
          >
            <div
              className={
                index === selectedButton
                  ? styles.groupChild5
                  : styles.groupChild
              }
            />
            <div className={styles.andyLogisticsPricekgContainer1}>
              <p className={styles.andyLogistics}>{company.logistics_name}</p>
              <p className={styles.andyLogistics}>Price/km: ₦{company.price}</p>
              <p className={styles.andyLogistics}>Rating:</p>
              {company.delivery_guarantee === null ? (
                <p className={styles.andyLogistics}>Delivery Guarantee: None</p>
              ) : (
                <p className={styles.andyLogistics}>
                  Delivery Guarantee: {company.delivery_guarantee} days
                </p>
              )}
            </div>
            <img className={styles.logo12Icon} alt="" src="/logo1-2@2x.png" />
            <div className={styles.materialSymbolsstarParent}>
              <img
                className={styles.materialSymbolsstarIcon}
                alt=""
                src="/materialsymbolsstar@2x.png"
              />
              <img
                className={styles.materialSymbolsstarIcon1}
                alt=""
                src="/materialsymbolsstar@2x.png"
              />
              <img
                className={styles.materialSymbolsstarIcon2}
                alt=""
                src="/materialsymbolsstar@2x.png"
              />
              <img
                className={styles.materialSymbolsstarIcon3}
                alt=""
                src="/materialsymbolsstar@2x.png"
              />
              <img
                className={styles.icbaselineStarHalfIcon}
                alt=""
                src="/icbaselinestarhalf@2x.png"
              />
              <div className={styles.div}>({company.rating})</div>
            </div>
          </button>
        ))}
        {isClicked ? (
          <button className={styles.groupWrapper} onClick={handleConfirm}>
            <div className={styles.rectangleParent13}>
              <div className={styles.groupChild13} />
              <div className={styles.confirmSelection}>Confirm Selection</div>
            </div>
          </button>
        ) : (
          <button className={styles.groupWrapper} disabled>
            <div className={styles.rectangleParent13}>
              <div className={styles.groupChild25} />
              <div className={styles.confirmSelection}>Confirm Selection</div>
            </div>
          </button>
        )}

        <div className={styles.totalCostN34000}>
          Total Cost: ₦{finalCost === "Unavailable" ? total : finalCost}
        </div>
      </div>
    </div>
  );
};

export default SelectLogisticsCompany;
