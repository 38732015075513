import { useState, useRef, useCallback, useEffect } from "react";
import SortBy from "../components/SortBy";
import PortalPopup from "../components/PortalPopup";
import BestSeller from "../components/BestSeller";
import Footer from "../components/Footer";
import FlashSales from "../components/FlashSales";
import LimitedStockDeals from "../components/LimitedStockDeals";
import BestSelling from "../components/BestSelling";
import HomeNavigation from "../components/HomeNavigation";
import SearchBar from "../components/SearchBar";
import styles from "./Homepage.module.css";
import axios from "axios";
import Trending from "../components/Trending";
import DealsOfTheDay from "../components/DealsOfTheDay";
import NewArrivals from "../components/NewArrivals";
import { useSearch } from "../context/SearchContext";
import Banner1 from "../components/Banner1";
import Banner2 from "../components/Banner2";
import { useAuth } from "../context/AuthContext";
import Banner3 from "../components/Banner3";
import Banner4 from "../components/Banner4";
import { useNavigate } from "react-router-dom";

const Homepage = () => {
  const { setChoice, setChoice1, setChoice2 } = useSearch();
  const { setIsLoggedIn, RefreshToken } = useAuth();
  const [trendingFarmers, setTrendingFarmers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setChoice("Popularity");
    setChoice1("Popularity");
    setChoice2("Popularity");
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  const images = [
    // <img src="/storage.jpg" alt="Storage" />,
    // <img src="/farm.jpeg" alt="Farm" />,
    // <img src="/logistics.jpeg" alt="Logistics" />,
    // <img src="/livestock.jpeg" alt="Livestock" />,
    <Banner1 />,
    <Banner2 />,
    <Banner3 />,
    <Banner4 />,
  ];
  // const captions = [
  //   "Kani Silos",
  //   "Mayor Farms",
  //   "Xpo Logistics",
  //   "Sunny Livestocks",
  // ];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const goToNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  useEffect(() => {
    const interval = setInterval(goToNextImage, 3000); // Change slide every 3 seconds
    return () => clearInterval(interval);
  }, [currentImageIndex]);

  useEffect(() => {
    axios
      .get(`https://farmjoint-396477185764.us-central1.run.app/farmers/?ordering=-successful_orders`)
      .then((response) => {
        console.log(response.data);
        setTrendingFarmers(response.data);
      })
      .catch((error) => {
        console.error("Error updating farmers:", error);
      });
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn === "true") {
      setIsLoggedIn(isLoggedIn);
      RefreshToken();
    }
  }, []);
  const handleClick = () => {
    navigate("/marketplace");
  };
  const handleFarmers = (farmer) => {
    navigate("/farmers-view-page", { state: { farmer } });
  };

  return (
    <>
      <div className={styles.homepage}>
        <div className={styles.frameParent}>
          <div className={styles.slideshow}>
            <div className={styles.ezgif1Icon}>{images[currentImageIndex]}</div>
            {/* <div className={styles.caption}>{captions[currentImageIndex]}</div> */}
          </div>
          <BestSelling />
          <div className={styles.farmersOfTheContainer}>
            <span className={styles.farmersOfThe}>Trending </span>
            <span className={styles.week}>Farms</span>
          </div>
          <div className={styles.farmersoftheweek}>
            <div className={styles.groupParent}>
              {trendingFarmers.slice(0, 7).map((farmer, index) => (
                <button
                  key={index}
                  className={styles.rectangleContainer}
                  onClick={() => {
                    handleFarmers(farmer);
                  }}
                >
                  <div className={styles.groupInner} />
                  <div className={styles.mandraFarms}>{farmer.farm_name}</div>
                  <div className={styles.abuja}>{farmer.farm_state}</div>
                  <div className={styles.div}>4.8</div>
                  <img
                    className={styles.groupIcon}
                    alt=""
                    src="/group-133.svg"
                  />
                  <img
                    className={styles.rectangleIcon}
                    alt=""
                    src={farmer.farm_images?.[0]?.image || ""}
                  />
                </button>
              ))}
            </div>
          </div>
          <FlashSales />
          <section className={styles.groupWrapper}>
            <div className={styles.rectangleParent}>
              <div className={styles.groupChild} />
              <img
                className={styles.bba003827272830485a6b84d4dLarIcon}
                alt=""
                src="/851951bba003827272830485a6b84d4d-largeremovebgpreview-4@2x.png"
              />
              <img
                className={styles.bba003827272830485a6b84d4dLarIcon1}
                alt=""
                src="/851951bba003827272830485a6b84d4d-largeremovebgpreview-5@2x.png"
              />
              <img
                className={styles.pngTomato187024Icon}
                alt=""
                src="/png-tomato-18702-4@2x.png"
              />
              <img
                className={styles.lettuceRemovebgPreview3Icon}
                alt=""
                src="/lettuceremovebgpreview-3@2x.png"
              />
              <img
                className={styles.lettuceRemovebgPreview4Icon}
                alt=""
                src="/lettuceremovebgpreview-4@2x.png"
              />
              <div className={styles.getUpTo}>Get up to 60% OFF</div>
              <button
                onClick={handleClick}
                className={styles.rectangleGroup}
                data-animate-on-scroll
              >
                <div className={styles.groupItem} />
                <div className={styles.shopNow}>SHOP NOW</div>
              </button>
              <div className={styles.vEG}>V E G E T A B L E S</div>
              <img
                className={styles.sweetRedPeppersCopy600x6Icon}
                alt=""
                src="/sweetredpeppers--copy600x600removebgpreview-3@2x.png"
              />
            </div>
          </section>
          <div className={styles.ban}>
            <div className={styles.groupParent1}>
              <BestSeller />
              <NewArrivals />
              <DealsOfTheDay />
              <Trending />
            </div>
          </div>
          <LimitedStockDeals />
          <div className={styles.rectangleWrapper}>
            <Footer />
          </div>
          <HomeNavigation />
        </div>

        <SearchBar
          dimensionText="/phuserlight.svg"
          dimensionText2="/bibasketfill.svg"
        />
      </div>
    </>
  );
};

export default Homepage;
