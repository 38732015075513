import { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PurchaseHistoryBuyers from "../components/PurchaseHistoryBuyers";
import DashboardNavigation from "../components/DashboardNavigation";
import SearchBar from "../components/SearchBar";
import Footer from "../components/Footer";
import axios from "axios";
import styles from "./BuyersDashboardPurchaseHis.module.css";
import { useAuth } from "../context/AuthContext";
const BuyersDashboardPurchaseHis = () => {
  const navigate = useNavigate();
  const { token } = useAuth();
  const [purchaseList, setPurchaseList] = useState([]);

  const onGroupButton2Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onGroupButton3Click = useCallback(() => {
    navigate("/marketplace");
  }, [navigate]);

  const onGroupButton4Click = useCallback(() => {
    navigate("/logistics");
  }, [navigate]);

  const onGroupButton5Click = useCallback(() => {
    navigate("/storage");
  }, [navigate]);

  const onGroupButton6Click = useCallback(() => {
    navigate("/farmers-deals");
  }, [navigate]);

  const onGroupButton7Click = useCallback(() => {
    navigate("/track-order");
  }, [navigate]);

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = `JWT ${token}`;
    axios
      .get(`https://farmjoint-396477185764.us-central1.run.app/purchase-history/`)
      .then((response) => {
        console.log(response.data);
        setPurchaseList(response.data);
      })
      .catch((error) => {
        console.error("Error updating geolocation:", error);
      });
  }, []);

  return (
    <div className={styles.buyersDashboardPurchaseHis}>
      <div className={styles.rectangleParent}>
        <div className={styles.frameChild} />
        <PurchaseHistoryBuyers />
        {purchaseList.map((product, index) => {
          const createdDate = new Date(product.created_date);
          const options = { year: "numeric", month: "long", day: "numeric" };
          const formattedDate = createdDate.toLocaleDateString(
            "en-US",
            options
          );
          return (
            <div className={styles.thJuly2023Parent} key={index}>
              <div className={styles.thJuly2023}>{formattedDate}</div>
              <div className={styles.cabbage}>{product.product.name}</div>
              <div className={styles.kg}>{product.quantity} kg</div>
              <div className={styles.n10000}>N{product.unit_price}</div>
              <img
                className={styles.pngimgIcon}
                alt=""
                src={product.product.images[0].image}
              />
            </div>
          );
        })}

        <div className={styles.frameItem} />
        <div className={styles.rectangleGroup}>
          <div className={styles.groupChild} />
          <div className={styles.date}>Date</div>
          <div className={styles.product}>Product</div>
          <div className={styles.quantity}>Quantity</div>
          <div className={styles.cost}>Cost</div>
        </div>
        <footer className={styles.footer} id="footer">
          <Footer />
        </footer>
      </div>
      <DashboardNavigation
        onGroupButton2Click={onGroupButton2Click}
        onGroupButton3Click={onGroupButton3Click}
        onGroupButton4Click={onGroupButton4Click}
        onGroupButton5Click={onGroupButton5Click}
        onGroupButton6Click={onGroupButton6Click}
        onGroupButton7Click={onGroupButton7Click}
      />

      <SearchBar
        dimensionText="/phuserlight3.svg"
        dimensionText2="/bibasketfill2.svg"
        propRight="5.4%"
      />
    </div>
  );
};

export default BuyersDashboardPurchaseHis;
