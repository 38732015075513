import { useCallback, useState } from "react";
import LogisticsNavigation from "../components/LogisticsNavigation";
import "antd/dist/antd.min.css";
import { Input } from "antd";
import Footer from "../components/Footer";
import { SearchOutlined } from "@ant-design/icons";
import axios from "axios";
import NextPageBar from "../components/NextPageBar";
import AvailableLogistics from "../components/AvailableLogistics";
import LogisticsFilter from "../components/LogisticsFilter";
import SearchBar from "../components/SearchBar";
import styles from "./Logistics.module.css";
import { useSearch } from "../context/SearchContext";
const Logistics = () => {
  const [search, setSearch] = useState([]);
  const { setFacilities } = useSearch();
  const [showDropdown, setShowDropdown] = useState(false);
  const [input, setInput] = useState();

  const onSearch = () => {
    setShowDropdown(false);
    axios
      .get(`https://farmjoint-396477185764.us-central1.run.app/all-vehicles/?search=${input}`)
      .then((response) => {
        setFacilities(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error while searching:", error);
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setShowDropdown(false);
      axios
        .get(`https://farmjoint-396477185764.us-central1.run.app/all-vehicles/?search=${input}`)
        .then((response) => {
          setFacilities(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error while searching:", error);
        });
    }
  };
  const getFilters = () => {
    axios
      .get(`https://farmjoint-396477185764.us-central1.run.app/all-vehicles/?search=${input}`)
      .then((response) => {
        setSearch(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error while searching:", error);
      });
  };
  const select = (item) => {
    axios
      .get(`https://farmjoint-396477185764.us-central1.run.app/all-vehicles/?search=${item.type}`)
      .then((response) => {
        setFacilities(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error while searching:", error);
      });
  };
  return (
    <div
      className={styles.logistics}
      onClick={() => {
        setShowDropdown(false);
      }}
    >
      <div className={styles.frameParent}>
        <div className={styles.pexelsTomFisk97080112Parent}>
          <img
            className={styles.pexelsTomFisk97080112Icon}
            alt=""
            src="/pexels1@2x.png"
          />
          <div className={styles.find}>Find a Logistics Company Around...</div>
          <button className={styles.frameWrapper} onClick={onSearch}>
            <div className={styles.frameContainer}>
              <div className={styles.rectangleContainer}>
                <div className={styles.frameInner} />
              </div>
              <div className={styles.searchWrapper}>
                <div className={styles.search}>Search</div>
              </div>
            </div>
          </button>
          <Input
            className={styles.groupInput}
            type="search"
            style={{
              width: "56.9%",
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
            }}
            prefix={<SearchOutlined />}
            size="large"
            placeholder="    Input type or location"
            onChange={(e) => {
              setInput(e.target.value);
              getFilters();
              setShowDropdown(true);
            }}
            onKeyDown={handleKeyDown}
          />
          {showDropdown && (
            <div className={styles.searchDropdown}>
              {search.slice(0, 10).map((item, index) => (
                <div
                  key={index}
                  onClick={() => {
                    select(item);
                    setShowDropdown(false);
                  }}
                >
                  <img
                    className={styles.pic}
                    alt=""
                    src={item.logistics_images[0].image}
                  />{" "}
                  <span>
                    <b className={styles.item}>
                      {item.type || item.logistics_name}
                    </b>
                    <b className={styles.in}>in</b>
                    <b className={styles.cat}>{item.state}</b>
                  </span>
                </div>
              ))}
            </div>
          )}
        </div>
        <AvailableLogistics />
        <div className={styles.footer}>
          <Footer />
        </div>
        <LogisticsNavigation />

        <LogisticsFilter />
      </div>
      <SearchBar
        dimensionText="/phuserlight2.svg"
        dimensionText2="/bibasketfill2.svg"
        propRight="5.41%"
      />
    </div>
  );
};

export default Logistics;
