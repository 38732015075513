import {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import axios from "axios";
import Cookies from "js-cookie";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLogInPopupOpen, setLogInPopupOpen] = useState(false);
  const [keepLoggedIn, setKeepLoggedIn] = useState(false);
  const [token, setToken] = useState(Cookies.get("jwtToken") || null);
  const [userRole, setUserRole] = useState(null);
  const [userName, setUserName] = useState(null);
  const [userId, setUserId] = useState(null);
  const [refreshToken, setRefreshToken] = useState(
    Cookies.get("refreshToken") || null
  );

  const RefreshToken = async () => {
    try {
      const response = await axios.post(
        "https://farmjoint-396477185764.us-central1.run.app/refresh/",
        { refresh: refreshToken },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const newToken = response.data.access;
        setToken(newToken);
        Cookies.set("jwtToken", newToken, { secure: true, sameSite: "strict" });

        reset(newToken);
        return newToken;
      } else {
        handleLogout();
        throw new Error("Token refresh failed");
      }
    } catch (error) {
      console.error("Token refresh error:", error);
      handleLogout();
    }
  };

  const reset = (newToken) => {
    const tokenParts = newToken.split(".");
    const payload = JSON.parse(atob(tokenParts[1]));
    const user_type = payload.user_type;
    const user_name = payload.name;
    const user_id = payload.user_id;
    setUserRole(user_type);
    setUserName(user_name);
    setUserId(user_id);
    Cookies.set("userRole", user_type, { secure: true, sameSite: "strict" });
    Cookies.set("userName", user_name, { secure: true, sameSite: "strict" });
    Cookies.set("userId", user_id, { secure: true, sameSite: "strict" });
  };

  useEffect(() => {
    const jwtToken = Cookies.get("jwtToken");
    const storedUserRole = Cookies.get("userRole");
    const storedUserName = Cookies.get("userName");
    const storedUserId = Cookies.get("userId");
    if (jwtToken) {
      setIsLoggedIn(true);
      setToken(jwtToken);
      setUserRole(storedUserRole);
      setUserName(storedUserName);
      setUserId(storedUserId);
    }

    console.log("userRole:", storedUserRole);
    console.log("userName:", storedUserName);
    console.log("userid:", storedUserId);
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      await RefreshToken();
    }, 2 * 60 * 60 * 1000);

    return () => clearInterval(interval);
  }, [isLoggedIn]);

  const handleLoginSuccess = useCallback(
    (token, userRole, userName, userId, refresh) => {
      setIsLoggedIn(true);
      setToken(token);
      setRefreshToken(refresh);
      Cookies.set("jwtToken", token, { secure: true, sameSite: "strict" });
      Cookies.set("refreshToken", refresh, { expires: 365 });
      Cookies.set("userRole", userRole, { secure: true, sameSite: "strict" });
      Cookies.set("userName", userName, { secure: true, sameSite: "strict" });
      Cookies.set("userId", userId, { secure: true, sameSite: "strict" });
    },
    []
  );

  const handleLogout = useCallback(() => {
    setIsLoggedIn(false);
    setKeepLoggedIn(false);
    setToken(null);
    setRefreshToken(null);
    Cookies.remove("jwtToken");
    Cookies.remove("refreshToken");
    setUserRole("");
    setUserName("");
    setUserId("");
    localStorage.removeItem("isLoggedIn");
  }, []);

  const value = {
    isLoggedIn,
    setIsLoggedIn,
    token,
    handleLoginSuccess,
    handleLogout,
    setUserRole,
    userRole,
    userName,
    setUserName,
    userId,
    RefreshToken,
    refreshToken,
    setRefreshToken,
    setUserId,
    selectedOption,
    keepLoggedIn,
    setKeepLoggedIn,
    setSelectedOption,
    isLogInPopupOpen,
    setLogInPopupOpen,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
