import React from "react";

const CustomMarker = () => (
  <svg
    width="28"
    height="44"
    viewBox="0 0 28 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.3999 12.375C1.3999 9.09295 2.7274 5.94532 5.09036 3.62455C7.45332 1.30379 10.6582 0 13.9999 0C17.3416 0 20.5465 1.30379 22.9094 3.62455C25.2724 5.94532 26.5999 9.09295 26.5999 
  12.375C26.5999 15.6571 25.2724 18.8047 22.9094 21.1254C20.5465 23.4462 17.3416 24.75 13.9999 24.75C10.6582 24.75 7.45332 23.4462 5.09036 21.1254C2.7274 18.8047 1.3999 15.6571 1.3999 12.375ZM13.9999 
  6.875C14.7699 6.875 15.3999 6.25625 15.3999 5.5C15.3999 4.74375 14.7699 4.125 13.9999 4.125C9.3624 4.125 5.5999 7.82031 5.5999 12.375C5.5999 13.1313 6.2299 13.75 6.9999 13.75C7.7699 13.75 8.3999 13.1313 8.3999 12.375C8.3999 9.34141 10.9112 6.875 13.9999 6.875ZM11.1999 41.25V27.2508C13.0512 27.5831 
  14.9486 27.5831 16.7999 27.2508V41.25C16.7999 42.7711 15.5487 44 13.9999 44C12.4512 44 11.1999 42.7711 11.1999 41.25Z"
      fill="#F91C1C"
    />
  </svg>
);

export default CustomMarker;
