import { useState, useCallback, useEffect } from "react";
import FarmersDealProductDescripti from "./FarmersDealProductDescripti";
import PortalPopup from "./PortalPopup";
import styles from "./FdProductCard.module.css";
import axios from "axios";
import Cookies from "js-cookie";
import { useAuth } from "../context/AuthContext";
import { useWishlistContext } from "../context/WishlistContext";
import { useNavigate } from "react-router-dom";

const FdProductCard = ({ product, productImage }) => {
  const { userRole } = useAuth();
  const navigate = useNavigate();

  const [reloadPage, setReloadPage] = useState(false);
  const { wishlistId, setWishlistItems1, wishlistItems1 } =
    useWishlistContext();
  const [isHeart, setHeart] = useState(() => {
    return Cookies.get(`isHeart_${product.id}`) === "true";
  });
  const [
    isFarmersDealProductDescriptiOpen,
    setFarmersDealProductDescriptiOpen,
  ] = useState(false);

  const openFarmersDealProductDescripti = useCallback(() => {
    setFarmersDealProductDescriptiOpen(true);
  }, []);

  const closeFarmersDealProductDescripti = useCallback(() => {
    setFarmersDealProductDescriptiOpen(false);
  }, []);
  useEffect(() => {
    if (reloadPage) {
      window.location.reload();
    }
  }, [reloadPage]);
  const handleRedHeart = () => {
    const updatedIsHeart = true;
    setHeart(updatedIsHeart);
    Cookies.set(`isHeart_${product.id}`, updatedIsHeart);
    const data = {
      product_id: product.id,
    };

    axios
      .post(
        `https://farmjoint-396477185764.us-central1.run.app/wishlists/${wishlistId}/items/`,
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        return axios.get(`https://farmjoint-396477185764.us-central1.run.app/wishlists/${wishlistId}/`);
      })
      .then((response) => {
        setWishlistItems1(response.data.items);
        console.log(response.data);
        console.log("wishlistItems1", wishlistItems1);
        setReloadPage(true);
      })

      .catch((error) => {
        console.error("Error fetching wishlist items:", error);
      });
  };
  const handleWhiteHeart = () => {
    const updatedIsHeart = false;
    setHeart(updatedIsHeart);
    Cookies.set(`isHeart_${product.id}`, updatedIsHeart);

    const data = {
      product_id: product.id,
    };

    axios
      .post(
        `https://farmjoint-396477185764.us-central1.run.app/wishlists/${wishlistId}/items/`,
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        return axios.get(`https://farmjoint-396477185764.us-central1.run.app/wishlists/${wishlistId}/`);
      })
      .then((response) => {
        setWishlistItems1(response.data.items);
        console.log(response.data);
        console.log("wishlistItems1", wishlistItems1);
        setReloadPage(true);
      })

      .catch((error) => {
        console.error("Error fetching wishlist items:", error);
      });
  };
  const handleFarmer = () => {
    const serializableData = {
      farmer_id: product.farmer_id,
    };
    console.log(product.farmer_id);
    navigate("/farmers-view-page", { state: { product: serializableData } });
  };
  return (
    <>
      <div className={styles.rectangleParent}>
        <div className={styles.componentChild} />
        <div className={styles.lettuce}>{product.name}</div>
        <b className={styles.n180kg}>₦{product.sale_price}/kg</b>
        <b className={styles.n200kg}>₦{product.regular_price}/kg</b>
        <button onClick={handleFarmer} className={styles.sunnyFarms}>
          {product.farmer}
        </button>
        <img
          className={styles.lettuceRemovebgPreview2Icon}
          alt=""
          src={productImage}
          onClick={openFarmersDealProductDescripti}
        />
        <button
          className={styles.rectangleGroup}
          onClick={openFarmersDealProductDescripti}
        >
          <div className={styles.groupChild} />
          <div className={styles.viewProduct}>View Product</div>
        </button>
        <img className={styles.componentItem} alt="" src="/line-62.svg" />
        <img className={styles.componentInner} alt="" src="/group-344.svg" />
        {userRole === "Customer" &&
          (isHeart === true ? (
            <button onClick={handleWhiteHeart}>
              <img
                className={styles.vectorIcon}
                alt=""
                src="/phheartthin1.svg"
              />
            </button>
          ) : (
            <button onClick={handleRedHeart}>
              <img
                className={styles.vectorIcon}
                alt=""
                src="/phheartthin.svg"
              />
            </button>
          ))}
      </div>
      {isFarmersDealProductDescriptiOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeFarmersDealProductDescripti}
        >
          <FarmersDealProductDescripti
            product={product}
            productImage={productImage}
            onClose={closeFarmersDealProductDescripti}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default FdProductCard;
