import { useState, useCallback, useEffect } from "react";
import axios from "axios";
import styles from "./Footer.module.css";
import AddToCart from "./AddToCart";
import { useNavigate } from "react-router-dom";
import { useCartContext1 } from "../context/CartContext1";
import PortalPopup from "./PortalPopup";
import { useAuth } from "../context/AuthContext";
import LogIn from "./LogIn";

const Footer = () => {
  const [isAddToCart1Open, setAddToCart1Open] = useState(false);
  const history = useNavigate();
  const { isLoggedIn, userRole, isLogInPopupOpen, setLogInPopupOpen } =
    useAuth();

  const handleAccount = () => {
    if (isLoggedIn) {
      if (userRole === "Customer") {
        history("/buyers-dashboard-profile"); // Redirect to the buyer dashboard
      } else if (userRole === "Farmer") {
        history("/farmers-dashboard-profile");
      } else if (userRole === "Logistics") {
        history("/logistics-dashboard-profile");
      } else if (userRole === "Storage") {
        history("/storage-dashboard-profile");
      }
    } else {
      openLogInPopup();
    }
  };
  const openAddToCart = useCallback(() => {
    setAddToCart1Open(true);
    document.body.style.overflow = "hidden";
  }, []);

  const closeAddToCart1 = useCallback(() => {
    setAddToCart1Open(false);
    document.body.style.overflow = "auto";
  }, []);
  const openLogInPopup = useCallback(() => {
    setLogInPopupOpen(true);
  }, []);

  const closeLogInPopup = useCallback(() => {
    setLogInPopupOpen(false);
  }, []);

  return (
    <>
      <div className={styles.rectangleParent}>
        <div className={styles.componentChild} />
        <div className={styles.signUpAnd}>Sign up and stay up to date.</div>
        <div className={styles.copyright2023Farmjoint}>
          © Copyright 2023 Farmjoint
        </div>
        <div className={styles.aboutBlogContactContainer}>
          <p className={styles.about}>About</p>
          <p className={styles.about}>Blog</p>
          <p className={styles.about}>Contact</p>
          <p className={styles.about}>Service</p>
        </div>
        <div className={styles.myAccountShoppingContainer}>
          <p className={styles.about}>
            <button onClick={handleAccount}>My Account</button>
          </p>
          <p className={styles.about}>
            <button onClick={openAddToCart}>Shopping Cart </button>
          </p>
        </div>
        <div className={styles.myAccountShoppingContainer1}>
          <p className={styles.about}>
            No.3 Koton Karfee Close, Area 2, Garki Abuja.
          </p>
        </div>
        <div className={styles.componentItem} />
        <div className={styles.componentInner} />
        <img
          className={styles.fa6SolidenvelopeOpenTextIcon}
          alt=""
          src="/fa6solidenvelopeopentext.svg"
        />
        <div className={styles.callUs247}>CALL US 24/7</div>
        <b className={styles.address}>ADDRESS</b>
        <b className={styles.account}>ACCOUNT</b>
        <b className={styles.information}>INFORMATION</b>
        <b className={styles.subscribe}>SUBSCRIBE</b>
        <div className={styles.emailUs}>EMAIL US</div>
        <div className={styles.followUs}>FOLLOW US</div>
        <div className={styles.div}>+234-703-1234-567</div>
        <div className={styles.contactfarmjointcom}>contact@farmjoint.com</div>
        <img className={styles.fafacebookIcon} alt="" src="/vector6.svg" />
        <img className={styles.mdiinstagramIcon} alt="" src="/vector.svg" />
        <img className={styles.mditwitterIcon} alt="" src="/vector4.svg" />
        <img className={styles.mdilinkedinIcon} alt="" src="/vector5.svg" />
        <input
          className={styles.rectangleInput}
          placeholder="        Your email"
          type="email"
        />
        <button className={styles.groupParent}>
          <div className={styles.rectangleWrapper}>
            <div className={styles.groupChild} />
          </div>
          <div className={styles.submit}>Submit</div>
        </button>
        <img
          className={styles.faSolidheadphonesAltIcon}
          alt=""
          src="/fasolidheadphonesalt.svg"
        />
      </div>
      {isAddToCart1Open && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top right"
          onOutsideClick={closeAddToCart1}
        >
          <AddToCart onClose={closeAddToCart1} />
        </PortalPopup>
      )}
      {isLogInPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeLogInPopup}
        >
          <LogIn onClose={closeLogInPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default Footer;
